<template>
  <base-layout>
    <SunpodAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus :title="$t('components.pods.sunpods.headline')"  @plus="add" :hide-plus="false" />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template>
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem
                    :text="item.id"
                    :truncate="-5"
                    :to="{ name: 'ViewSunpodProfile', params: { id: item.id } }"
                  />
                  <FSTableRowItem :text="item.name" />
                  <FSTableRowItem :text="item.instance" />

                  <FSTableRowItem
                    :text="
                      item.assigned_edgebox ? item.assigned_edgebox.name : '--'
                    "
                  />
                  <FSTableRowItem>
                    <HeartbeatStatus :status="item.is_active" />
                  </FSTableRowItem>
                  <FSTableRowItem class="flex items-center">
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :isDoorOpen="
                        item.assigned_edgebox
                          ? item.assigned_edgebox.is_door_open
                          : false
                      "
                      :sunpodId="item.id"
                      @edit="edit(item)"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import BaseLayout from '@/views/shared/BaseLayout.vue'

import SunpodAddEdit from '@/views/sunpods/SunpodAddEdit'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { SunpodsConfig } from '@/config/SunpodsConfig'
import { EventBus } from '@/utils'
export default {
  name: 'SunpodIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    SunpodAddEdit,
    MoreActionsDropdown: () =>
      import('@/views/sunpods/MoreActionsDropdown.vue'),
    HeartbeatStatus: () => import('@/components/badge/HeartbeatStatus'),
  },
  data() {
    return {
      fstId: 'SunpodIndex',
      indexDataEndpoint: SunpodsConfig.api.index,
      tableHeaders: [
        {
         text: this.$t(
            'components.pods.sunpods.table.columns.id'
          ),
          width: '10%',
          sort: null,
        },
        {
         text: this.$t(
            'components.pods.sunpods.table.columns.name'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.table.columns.instance'
          ),
          width: '15%',
          sort: null,
        },
        {
           text: this.$t(
            'components.pods.sunpods.table.columns.assignedEdgebox'
          ),
          width: '15%',
          sort: null,
        },
        {
         text: this.$t(
            'components.pods.sunpods.table.columns.status'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.sunpods.table.columns.actions'
          ),
          width: '15%',
          sort: null,
        },
      ],
    }
  },
  methods: {
    getItemVariant(data) {
      if (data) return 'green'
      else return 'gray'
    },
    add: function() {
      EventBus.$emit(SunpodsConfig.events.editingData, {})
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(SunpodsConfig.events.editingData, {
        ...item,
        assigned_edgebox: item.assigned_edgebox.id,
        sun_pod_area: item.sun_pod_area ? item.sun_pod_area.id : null,
      })
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
