<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-5 pt-12 w-full">
      <div class="items-center w-1/2  header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-5 mt-5">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
          class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
        >
          <AppInput
            type="text"
            rules="required"
            name="Name"
           :label="$t('components.pods.edgebox.addEdit.title.name')"
            v-model="form.name"
          />
          <AppInput
            type="text"
            rules="required"
            name="Imei"
             :label="$t('components.pods.edgebox.addEdit.title.imei')"
            v-model="form.imei"
          />
          <AppInput
            type="text"
            rules="required"
            name="Mac Address"
           :label="$t('components.pods.edgebox.addEdit.title.macAddress')"
            v-model="form.mac_address"
          />

          <button type="submit" ref="submitButton" class="hidden">
           {{
                $t('components.stepNavigation.save')
              }}
          </button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">{{
            $t('components.stepNavigation.cancel')
          }}</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">{{
          actionButtonText
        }}</t-button>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { EdgeboxConfig } from '@/config/EdgeboxConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import xMan from '@/utils/xMan'
export default {
  name: 'AddEditSunpod',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
  },
  data() {
    return {
      sorId: EdgeboxConfig.events.sorId,
      isLoading: false,
      form: {
        name: null,
        imei: null,
        mac_address: null,
      },
      item: {},
    }
  },

  mounted() {
    EventBus.$on(EdgeboxConfig.events.editingData, (item) => {
      this.item = item
      this.form.name = this.item.name
      this.form.imei = this.item.imei
      this.form.mac_address = this.item.mac_address
    })
  },
  computed: {
    titleText() {
      return this.isEditing ?  
          this.$t('components.pods.edgebox.addEdit.headline.edit')
        : this.$t('components.pods.edgebox.addEdit.headline.add')
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    actionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
  },

  methods: {
    init() {
      this.form = {
        name: null,
        imei: null,
        mac_address: null,
      }
    },
    submit() {
      this.$refs.submitButton.click()
    },
    showModal() {
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },

    async confirmSubmit() {
      const formProxy = { ...this.form }
      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })

      console.log('form = ', formProxy)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? EdgeboxConfig.api.update(this.item.id)
        : EdgeboxConfig.api.create
      let data = new xMan(formProxy).toFormData()

      let message = this.isEditing
        ? 'Sunpod updated successfully'
        : 'Sunpod added successfully'

      this.isLoading = true
      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
        dispatchEvent(new Event(EdgeboxConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.form = {}
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('edgebox-err', { e })

        this.$notify({
          group: 'bottomLeft',
          type: 'error',
          title: `Error Occured [Code: ${e.status}]`,
          text: e.data ?? 'The action could not be executed',
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
