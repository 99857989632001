<template>
  <base-layout>
    <EdgeboxAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus :title="$t('components.pods.edgebox.headline')" @plus="add" :hide-plus="false" />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template>
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem :text="item.name" />
                  <FSTableRowItem :text="item.imei" />
                  <FSTableRowItem :text="item.mac_address" />
                  <FSTableRowItem>
                    <OtoEditIcon @click="edit(item)" />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import EdgeboxAddEdit from '@/views/sunpods/EdgeboxAddEdit.vue'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'

import { EdgeboxConfig } from '@/config/EdgeboxConfig'
import { EventBus } from '@/utils'
export default {
  name: 'EdgeboxIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    EdgeboxAddEdit,
  },
  data() {
    return {
      fstId: 'EdgeboxIndex',
      indexDataEndpoint: EdgeboxConfig.api.index,
      tableHeaders: [
        {
          text: this.$t(
            'components.pods.edgebox.table.columns.id'
          ),
          width: '20%',
          sort: null,
        },
        {
           text: this.$t(
            'components.pods.edgebox.table.columns.name'
          ),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.edgebox.table.columns.imei'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.pods.edgebox.table.columns.macAddress'
          ),
          width: '25%',
          sort: null,
        },
        {
           text: this.$t(
            'components.pods.edgebox.table.columns.actions'
          ),
          width: '5%',
          sort: null,
        },
      ],
    }
  },
  methods: {
    add: function() {
      EventBus.$emit(EdgeboxConfig.events.editingData, {})
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(EdgeboxConfig.events.editingData, item)
      dispatchEvent(new Event(EdgeboxConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
